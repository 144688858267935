<svelte:options
  customElement={{
    tag: 'upload-wizard',
    shadow: 'none',
  }} />

<script>
  import { writable } from 'svelte/store'

  // Preload
  import cardFront from 'Images/card_front.jpg'
  import selfie from 'Images/selfie.jpg'
  import face from 'Images/face.svg'

  // Components
  import Login from './steps/Login.svelte'
  import Select from './steps/Select.svelte'
  import Preview from './steps/Preview.svelte'
  import Upload from './steps/Upload.svelte'

  const I18n = window.I18n
  const currentStep = writable(1)

  const stepMap = {
    1: Login,
    2: Select,
    3: Preview,
    4: Upload,
  }

  const stepKeyMap = {
    1: 'login',
    2: 'select',
    3: 'preview',
    4: 'upload',
  }

  const maxSteps = Object.keys(stepMap).length

  let loginData
  let imageData

  function retryImage() {
    imageData = undefined
    dismissMessages(({ step }) => step >= 2)
    $currentStep = 2
  }

  function nextStep() {
    dismissMessages()
    currentStep.update(n => Math.min(n + 1, maxSteps))
  }

  function previousStep() {
    currentStep.update(n => Math.max(n - 1, 1))
  }

  function handleLogin(event) {
    loginData = event.detail
    nextStep()
  }

  function handleUpload(event) {
    imageData = event.detail
    nextStep()
  }

  function handleConfirm() {
    nextStep()
  }

  let messagesBox
  let messages = []
  const wrapMessage = message => (typeof message == 'string' ? { message, type: 'warning' } : message)
  const displayMessage = message => {
    message.step ||= $currentStep
    messages = [...messages, wrapMessage(message)]
    messagesBox.scrollIntoViewIfNeeded()
  }
  const dismissMessage = n => {
    messages.splice(n, 1)
    messages = messages
  }
  const not =
    fn =>
    (...args) =>
      !fn(...args)
  const dismissMessages = predicate => {
    if (predicate) messages = messages.filter(not(predicate))
    else messages = []
  }
  const handleDismiss = ({ detail: predicate }) => dismissMessages(predicate)

  window.err = displayMessage
</script>

<link rel="preload" as="image" href={cardFront} />
<link rel="preload" as="image" href={selfie} />
<link rel="preload" as="image" href={face} />

<div class="wizard container-fluid">
  <div bind:this={messagesBox} id="errorMessageOverlay">
    {#each messages as { message, type }, n}
      <div id="errorMessage" class="alert alert-{type} alert-dismissible fade show" role="alert">
        <div style="white-space: pre-wrap">{message}</div>
        <button type="button" class="btn-close" aria-label="Close" on:click={() => dismissMessage(n)}></button>
      </div>
    {/each}
  </div>

  <div class="step-banner">
    {I18n.t('site.steps.step_current_max', {
      current: $currentStep,
      max: 4,
    })}: {I18n.t('site.steps')[stepKeyMap[$currentStep]].name}
  </div>

  <svelte:component
    this={stepMap[$currentStep]}
    on:back={previousStep}
    on:login={handleLogin}
    on:upload={handleUpload}
    on:confirm={handleConfirm}
    on:message={({ detail }) => displayMessage(detail)}
    on:dismiss={handleDismiss}
    on:retry={retryImage}
    data={{ ...loginData, ...imageData }} />
</div>

<style>
  .wizard {
    background: #fff;
    min-height: 450px;
    position: relative;
  }

  .wizard .step-banner {
    background: #003063;
    color: #fff;
    padding: 0.4em 1em;
    text-transform: uppercase;
    line-height: 2em;
    margin: 0.4em 0;
  }
</style>
