// Import Svelte components
import 'Views/upload/components/index'

// I18n
import i18n from 'i18n-js'

// Bootstrap
import 'bootstrap'

// Add custom UI JS code
window.I18n = i18n
