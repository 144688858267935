<script>
  import Cropper from 'cropperjs'

  import { ObjectURLHandler } from '~/ObjectURLHandler'
  import { createEventDispatcher } from 'svelte'
  import { Modal } from 'bootstrap'
  import face from 'Images/face.svg'
  import WebcamPicker from './WebcamPicker.svelte'

  // Webcam erstmal aus Datenschutzbedenken deaktivieren
  // const hasCam = navigator.mediaDevices.enumerateDevices().then(arr => arr.some(({ kind }) => kind === 'videoinput'))
  const hasCam = new Promise(resolve => {
    setTimeout(() => {
      resolve(false)
    }, 0)
  })

  const photoConfig = JSON.parse(document.getElementById('photo_config').innerText)

  const i18n = window.I18n

  const dispatch = createEventDispatcher()

  let selectedFile
  let fileInput
  let cropper
  let helpModal

  let webcamPicker = false

  /** @type {ObjectURLHandler} */
  const fileURLHandler = new ObjectURLHandler()

  function handleBack() {
    dispatch('back')
  }

  const validFileTypes = new Set(photoConfig.mime_types)

  const error = (message, scope) =>
    dispatch('message', {
      message,
      type: 'danger',
      scope: scope ? `image.${scope}` : `image`,
    })

  const dismiss = scope => {
    dispatch('dismiss', msg => msg.scope?.match(scope)?.index == 0)
  }

  function handleFileChange() {
    dismiss('image.file')
    const file = fileInput.files[0]

    const max_size = photoConfig.max_size_mb
    if (file.size > max_size * 1024 ** 2) return error(i18n.t('site.steps.select.errors.too_large', { size: max_size }), 'file')

    const validFileTypesString = Array.from(validFileTypes)
      .map(type => type.split('/')[1].toUpperCase())
      .join(', ')
    if (!validFileTypes.has(file.type)) return error(i18n.t('site.steps.select.errors.file_type', { types: validFileTypesString }), 'file')

    // Überprüfen Sie, ob das Bild darstellbar ist
    const img = new Image()
    img.onload = function () {
      // Bild wurde erfolgreich geladen
      selectedFile = file
      fileURLHandler.object = file
    }
    img.onerror = function () {
      // Bild konnte nicht geladen werden
      error(i18n.t('site.steps.select.errors.file_type', { types: validFileTypesString }), 'file')
    }
    img.src = URL.createObjectURL(file)
  }

  function handlePhotoTaken({ detail: file }) {
    selectedFile = file
    fileURLHandler.object = file
    webcamPicker = false
  }

  function imageChanged({ target: image }) {
    cropper?.destroy()
    const { width, height } = image

    cropper = new Cropper(image, {
      // Define the aspect ratio of the crop box
      aspectRatio: 3.5 / 4.5,
      // Show the white modal to highlight the crop box
      highlight: false, //true,

      autoCrop: true,
      autoCropArea: 1,

      // // Enable to create new crop box by dragging over the image
      dragCrop: false,
      dragMode: 'move',

      // Damit das Bild nciht über den Cropper raus kann
      viewMode: 3,

      // Enable to move the crop box
      cropBoxMovable: false,

      // Enable to resize the crop box
      cropBoxResizable: false,

      // Size limitation
      minCanvasWidth: 0,
      minCanvasHeight: 0,
      minCropBoxWidth: width,
      minCropBoxHeight: height,
      minContainerWidth: 0,
      minContainerHeight: 0,

      ready: function () {},

      crop: function (_data) {},
    })
  }

  function getCropped() {
    return new Promise(resolve => cropper.getCroppedCanvas().toBlob(resolve))
  }

  async function cropAndContinue() {
    dismiss('image.cropped')
    try {
      const data = {
        file: selectedFile,
        cropped: await getCropped(),
        ...cropper.getData(),
      }
      const { min_width, min_height } = photoConfig
      if (data.width < min_width || data.height < min_height)
        return error(
          i18n.t('site.steps.select.errors.cropped_too_small', {
            min_width: min_width,
            min_height: min_height,
            height: Math.floor(data.height),
            width: Math.floor(data.width),
          }),
          'cropped'
        )
      dispatch('upload', data)
    } catch (error) {
      error(`${i18n.t('site.error')}: ${error.message}`, 'cropped')
    }
  }
</script>

<div class="main-container" style="--background-image: url({face})">
  <input bind:this={fileInput} type="file" accept="image/*" on:change={handleFileChange} />

  <div bind:this={helpModal} class="modal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{i18n.t('site.usage')}</h5>
          <button title={i18n.t('actions.close')} type="button" class="btn-close" data-bs-dismiss="modal" aria-label={i18n.t('actions.close')}></button>
        </div>
        <div class="modal-body">
          {#each i18n.t('site.steps.select').cropper_usage || [] as usage}
            <p>{usage}</p>
          {/each}
        </div>
      </div>
    </div>
  </div>

  {#if selectedFile}
    <div id="image-container">
      <img alt={i18n.t('site.uploaded_image')} src={fileURLHandler.url} on:load={imageChanged} />
    </div>
    {#if cropper}
      <div class="btn-group" role="toolbar">
        <button
          title={i18n.t('site.steps.select.actions.rotate_ccw')}
          on:click={() => {
            cropper.rotate(-15)
          }}
          class="btn btn-secondary"><i class="bi bi-arrow-counterclockwise" /></button>
        <button
          title={i18n.t('site.steps.select.actions.rotate_cw')}
          on:click={() => {
            cropper.rotate(15)
          }}
          class="btn btn-secondary"><i class="bi bi-arrow-clockwise" /></button>
        <button
          title={i18n.t('site.steps.select.actions.zoom_in')}
          on:click={() => {
            cropper.zoom(0.1)
          }}
          class="btn btn-secondary"><i class="bi bi-zoom-in" /></button>
        <button
          title={i18n.t('site.steps.select.actions.zoom_out')}
          on:click={() => {
            cropper.zoom(-0.1)
          }}
          class="btn btn-secondary"><i class="bi bi-zoom-out" /></button>
        <button
          title={i18n.t('site.steps.select.actions.reset')}
          on:click={() => {
            cropper.reset()
          }}
          class="btn btn-secondary"><i class="bi bi-eraser-fill" /></button>

        <button
          title={i18n.t('site.steps.select.actions.reset')}
          on:click={() => {
            new Modal(helpModal).show()
          }}
          class="btn btn-info"><i class="bi bi-info-circle"></i></button>
      </div>
    {/if}
    {#if cropper}
      <button class="btn btn-primary" on:click={cropAndContinue}>
        <i class="bi bi-check2" />
        {i18n.t('site.steps.preview.link')}
      </button>
    {/if}
    <button
      class="btn btn-secondary"
      on:click={() => {
        selectedFile = undefined
        webcamPicker = false
        fileInput.value = ''
      }}>
      <i class="bi bi-arrow-repeat" />
      {i18n.t('site.steps.select.actions.new_image')}
    </button>
  {:else if webcamPicker}
    <WebcamPicker
      on:photo={handlePhotoTaken}
      on:cancel={() => {
        webcamPicker = false
      }} />
  {:else}
    <div id="image-container">
      {#await fetch(face).then(response => response.text())}
        <!-- Awaiting promise -->
      {:then content}
        <!-- eslint-disable svelte/no-at-html-tags -->
        {@html content}
        {#await hasCam}
          <button id="upload-button" class="btn btn-primary" on:click={() => fileInput.showPicker()}>
            <i class="bi bi-cloud-upload" />
            {i18n.t('actions.upload')}
          </button>
        {:then cam}
          {#if cam}
            <div class="btn-group-custom" id="upload-button" role="group" aria-label="Photo group">
              <button class="btn btn-primary" on:click={() => fileInput.showPicker()}>
                <i class="bi bi-cloud-upload" />
                {i18n.t('actions.upload')}
              </button>
              <button
                class="btn btn-info"
                on:click={() => {
                  webcamPicker = true
                }}>
                <i class="bi bi-camera"></i>
                {i18n.t('actions.webcam')}
              </button>
            </div>
          {:else}
            <button id="upload-button" class="btn btn-primary" on:click={() => fileInput.showPicker()}>
              <i class="bi bi-cloud-upload" />
              {i18n.t('actions.upload')}
            </button>
          {/if}
        {/await}
      {/await}
    </div>
    <p class="text-center">
      {#await hasCam then cam}
        {#if cam}
          {i18n.t('site.steps.upload.info_with_webcam')}
        {:else}
          {i18n.t('site.steps.upload.info')}
        {/if}
      {/await}
    </p>
    <div class="p-2 bg-secondary" id="upload-hint">
      <p>
        <b>{i18n.t('site.hint')}</b>
      </p>
      <ul>
        {#each i18n.t('site.steps.select.hints') as hint}
          <li>{hint}</li>
        {/each}
      </ul>
    </div>
  {/if}

  {#if !webcamPicker}
    <button class="btn" on:click={handleBack}>
      <i class="bi bi-arrow-left" />
      {i18n.t('actions.back')}
    </button>
  {/if}
</div>

<style>
  input[type='file'] {
    display: none;
  }
  .main-container {
    align-items: stretch;
  }
  .main-container,
  #image-container {
    display: flex;
    flex-direction: column;
    --gap: 10px;
    gap: var(--gap);
  }
  #image-container {
    position: relative;
    aspect-ratio: 35 / 45;
    contain: paint;
  }
  #image-container,
  #image-container + .btn-group {
    width: max(18rem, 30vh);
    align-self: center;
  }
  .btn-group button {
    margin: 0 !important;
  }
  #image-container + .btn-group {
    position: relative;
    top: calc(-1 * var(--gap));
  }
  #image-container > :global(svg) {
    max-width: 100%;
    height: auto;
  }
  #image-container > :global(svg > #fill) {
    fill-opacity: 0.5;
  }
  #image-container > :global(svg > #outside) {
    opacity: 0;
  }
  :global(.cropper-face) {
    background: none;
    background-image: var(--background-image);
    /* mix-blend-mode: multiply; */
    background-size: 100%;
    opacity: 1;
  }
  #upload-button {
    position: absolute;
    top: 46%;
    width: 100%;
  }
  .btn-group-custom {
    display: flex;
    flex-flow: column;
    gap: 0.4em;
  }
  .btn-group-custom#upload-button {
    top: 42%;
  }

  #upload-hint {
    background-color: #f2f5f7 !important;
  }
</style>
