<script>
  import { ObjectURLHandler } from '~/ObjectURLHandler'
  import { createEventDispatcher } from 'svelte'
  import card_front from 'Images/card_front.jpg'

  export let data

  let termsAndPrivacy = false

  const i18n = window.I18n

  /** @type {ObjectURLHandler} */
  const fileURLHandler = new ObjectURLHandler()

  $: if (data.cropped) fileURLHandler.object = data.cropped

  const dispatch = createEventDispatcher()

  function handleConfirm() {
    dispatch('confirm')
  }
</script>

<div class="d-flex flex-column align-items-stretch">
  {#if data}
    <div class="d-flex flex-column align-items-center">
      <svg viewBox="0 0 85.6 53.98">
        <!-- aspectRatio: 3.5 / 4.5 -->
        <defs>
          <clipPath id="card-clip">
            <rect height="100%" width="100%" rx="2.2" ry="2.2"></rect>
          </clipPath>
        </defs>
        <image clip-path="url(#card-clip)" height="100%" width="100%" href={card_front} alt="Vorschau" />
        <image x="3" y="15" height="31" width="24" href={fileURLHandler.url} alt="Vorschau" />
      </svg>
    </div>
  {/if}
  <div class="p-2 bg-secondary text-box">
    <b>
      {i18n.t('site.terms_and_privacy_heading')}
    </b>
    <p>
      {i18n.t('site.terms_and_privacy')}
    </p>
  </div>
  <label>
    <input class="form-check-input" type="checkbox" bind:checked={termsAndPrivacy} />
    <span id="terms_and_privacy_accept">{i18n.t('site.terms_and_privacy_accept')}</span>
  </label>
  <button disabled={!termsAndPrivacy} class="btn btn-primary" on:click={handleConfirm}>
    {i18n.t('site.steps.preview.upload_image')}
    <i class="bi bi-arrow-right" />
  </button>
  <button class="btn" on:click={() => dispatch('back')}>
    <i class="bi bi-arrow-left" />
    {i18n.t('site.steps.preview.not_satisfied')}
  </button>
</div>

<style>
  #terms_and_privacy_accept {
    user-select: none;
  }

  .text-box {
    background-color: #f2f5f7 !important;
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  svg {
    max-width: max(24rem, 50vh);
  }
</style>
