const encoder = new TextEncoder()

export default async (value, { target = 16, signal } = {}) => {
  const bytes = Math.floor(target / 8)
  const mask = Math.pow(2, target % 8) - 1

  const check = hash => {
    for (let byte = 0; byte < bytes; byte += 1) if (hash[byte] != 0) return false
    return !(hash[bytes] & mask)
  }

  for (let counter = 1; true; counter += 1) {
    const suffix = String(counter).padStart(14, '0')
    const concatenated = value + suffix
    const buffer = encoder.encode(concatenated)
    const hash = new Uint8Array(await crypto.subtle.digest('sha-256', buffer))
    if (signal?.aborted) return null
    if (check(hash)) return suffix
  }
}
