<script>
  import { createEventDispatcher } from 'svelte'
  import Selfie from 'Images/selfie.jpg'
  import meta from '~/meta'
  import proofOfWork from '~/proofOfWork'
  import { Tooltip } from 'bootstrap'
  import { Datepicker } from 'vanillajs-datepicker'
  import de from 'vanillajs-datepicker/locales/de'

  const dispatch = createEventDispatcher()

  const i18n = window.I18n

  let dateInput

  let loginProcess = false

  let cardHolderIdInput = ''
  $: cardHolderId = cardHolderIdInput.replaceAll(' ', '').padStart(14, '0')

  let birthDate
  let picker

  $: console.log(birthDate)

  Object.assign(Datepicker.locales, de)

  function datepicker(element) {
    picker = new Datepicker(element, {
      autohide: true,
      format: 'dd.mm.yyyy',
      language: 'de',
      weekStart: 1,
    })
  }

  function tooltip(element) {
    new Tooltip(element)
  }

  async function handleSubmit() {
    loginProcess = true
    dispatch('dismiss', message => message.scope === 'login')
    const proof_of_work = await proofOfWork(cardHolderId + birthDate, {
      target: meta.proofOfWorkStrength,
    })
    const response = await fetch(`${meta.clientsFindUrl}?card_holder_id=${cardHolderId}&birth_date=${birthDate}&proof_of_work=${proof_of_work}`)
    if (response.ok) dispatch('login', { card_holder_id: cardHolderId, birth_date: birthDate, proof_of_work })
    else
      dispatch('message', {
        message: (await response.json()).message || response.statusText,
        type: 'danger',
        scope: 'login',
      })
    loginProcess = false
  }
</script>

<form on:submit|preventDefault={handleSubmit}>
  <div class="row mb-3">
    <img class="header-image" src={Selfie} alt="Selfie-Bild" />
  </div>

  <div class="row">
    <div class="col-sm-12">
      <p class="lead text-center">{i18n.t('site.steps.login.welcome')}</p>
      <p class="text-center">{i18n.t('site.steps.login.instructions')}</p>
    </div>
  </div>

  <div class="row mb-3">
    <div class="form-group">
      <div class="input-group">
        <input
          disabled={loginProcess}
          name="card_holder_id"
          type="text"
          placeholder={i18n.t('site.steps.login.id')}
          class="form-control"
          bind:value={cardHolderIdInput}
          required />
        <span use:tooltip data-toggle="tooltip" data-placement="left" class="input-group-text" title={i18n.t('site.steps.login.card_holder_id_hint')}
          ><i class="bi bi-question-circle-fill"></i></span>
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="form-group">
      <div class="input-group">
        <input
          pattern={`\\d{1,2}\\.\\d{1,2}\\.\\d{4}`}
          type="text"
          name="birth_date"
          placeholder={i18n.t('site.steps.login.birth_date_placeholder')}
          use:datepicker
          bind:value={birthDate}
          bind:this={dateInput}
          on:changeDate={() => (birthDate = dateInput.value)}
          disabled={loginProcess}
          autocomplete="off"
          class="form-control"
          required />
        <button
          disabled={loginProcess}
          type="button"
          on:click|preventDefault={() => {
            picker?.show()
          }}
          class="input-group-text"><i class="bi bi-calendar"></i></button>
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12">
      <div class="form-group">
        <div class="d-grid">
          {#if loginProcess}
            <div class="d-flex justify-content-center p-4">
              <div class="spinner-border text-primary" style="width: 5rem; height: 5rem;" role="status" />
            </div>
          {:else}
            <button class="btn btn-primary btn-primary" type="submit">{i18n.t('site.actions.login')}</button>
          {/if}
        </div>
      </div>
    </div>
  </div>
</form>

<style>
  .header-image {
    background-position: center center;
    background-repeat: no-repeat;
    object-fit: cover;
    aspect-ratio: 3 / 1;

    @media (min-width: 1024px) {
      display: none;
    }
  }
</style>
