const revocationRegistry = new FinalizationRegistry(({ url }) => {
  if (url) URL.revokeObjectURL(url)
})

/** Manages object URLs and does its best to free them eventually */
export class ObjectURLHandler {
  #wrapper = {}

  /** @param {File} object */
  constructor(object) {
    revocationRegistry.register(this, this.#wrapper)
    if (object) this.object = object
  }

  /** A file to be referenced by a object URL.
   * @param {File} object
   */
  set object(object) {
    if (this.#wrapper.url) URL.revokeObjectURL(this.#wrapper.url)
    this.#wrapper.url = URL.createObjectURL(object)
    this.#wrapper.object = object
  }

  get object() {
    this.#wrapper.object
  }

  get url() {
    return this.#wrapper.url
  }
}
