<script>
  import { createEventDispatcher } from 'svelte'
  import meta from '~/meta'
  export let data

  const i18n = window.I18n

  const dispatch = createEventDispatcher()

  const form = new FormData()

  function tryAgain() {
    dispatch('retry')
  }

  let validationErrors
  ;['card_holder_id', 'birth_date', 'proof_of_work'].forEach(key => form.append(key, data[key]))
  ;['x', 'y', 'width', 'height', 'rotate'].forEach(key => form.append(key, data[key]))

  if (data.file instanceof File) form.append('image', data.file)
  else form.append('image', data.file, 'webcam.png')

  const upload = fetch(meta.uploadUrl, {
    method: 'post',
    body: form,
  })
    .then(resp => {
      if (!resp.ok)
        return resp.json().then(json => {
          if (json.validation_errors) validationErrors = json.validation_errors
          throw new Error(json.message)
        })
      else return resp.json()
    })
    .catch(error => {
      console.error(error)
      dispatch('message', { message: error.message, type: 'danger' })
      throw error.message
    })

  upload.then(() => {
    dispatch('message', {
      message: i18n.t('site.steps.upload.success_description'),
      type: 'success',
    })
  })
</script>

{#await upload}
  <div class="d-flex flex-column align-items-center">
    <p class="lead">{i18n.t('site.steps.upload.uploading')}</p>
    <div class="spinner-border text-primary" style="width: 5rem; height: 5rem;" role="status" />
  </div>
{:then}
  <div class="d-flex flex-column align-items-center">
    <i class="bi bi-check2-circle text-success result-icon"></i>
    <p class="display-3">{i18n.t('site.steps.upload.success')}</p>
  </div>
  <div>
    <p class="lead">{i18n.t('site.steps.upload.success_info')}</p>
  </div>
{:catch}
  <div class="d-flex flex-column align-items-center">
    <i class="bi bi-exclamation-circle text-danger result-icon"></i>
  </div>

  <p class="lead"><b>{i18n.t('site.steps.upload.error')}</b></p>
  {#if validationErrors}
    <ul>
      {#each validationErrors as reason}
        <li>{reason}</li>
      {/each}
    </ul>
  {/if}
  <button class="btn btn-primary" on:click={tryAgain}>
    <i class="bi bi-arrow-repeat" />
    {i18n.t('site.steps.upload.new_image')}
  </button>
{/await}

<style>
  div {
    text-align: center;
  }

  .result-icon {
    font-size: 8em;
  }
</style>
