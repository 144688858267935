<script>
  import { createEventDispatcher } from 'svelte'
  import face from 'Images/face.svg'

  const i18n = window.I18n

  const dispatch = createEventDispatcher()

  let video
  let canvas
  let stream
  let acknowledged = false

  let width, height

  stream = navigator.mediaDevices.getUserMedia({ video: true })
  stream.then(media => {
    ;({ width, height } = media.getVideoTracks()[0].getSettings())
  })

  async function capturePhoto() {
    const context = canvas.getContext('2d')
    context.drawImage(video, 0, 0, canvas.width, canvas.height)
    const photo = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'))

    dispatch('photo', photo)
  }

  function srcObject(node, stream) {
    node.srcObject = stream
    return {
      update(newStream) {
        if (node.srcObject != newStream) {
          node.srcObject = newStream
        }
      },
    }
  }
</script>

{#await stream}
  <div class="d-flex justify-content-center p-4">
    <div class="spinner-border text-primary" style="width: 5rem; height: 5rem;" role="status" />
  </div>
{:then input}
  <div class="overlay-container">
    <!-- eslint-disable svelte/valid-compile -->
    <video width={width} height={height} use:srcObject={input} bind:this={video} autoplay playsinline />
    <!-- <div class="overlay" style="--image: url('{face}')" /> -->
    {#await fetch(face).then(response => response.text())}
      <!-- Awaiting promise -->
    {:then content}
      <!-- eslint-disable svelte/no-at-html-tags -->
      {@html content}
    {/await}
  </div>
  <canvas bind:this={canvas} width={width} height={height} style="display:none;" />
{/await}

<label>
  <input class="form-check-input" type="checkbox" bind:checked={acknowledged} />
  <span id="confirm_camera_usage">{i18n.t('site.steps.select.confirm_camera_usage')}</span>
</label>

{#await stream}
  <button class="btn btn-secondary" disabled="true">{i18n.t('site.steps.select.take_photo')}</button>
{:then}
  <button class="btn btn-primary" on:click={capturePhoto} disabled={!acknowledged}>
    <i class="bi bi-camera"></i>
    {i18n.t('site.steps.select.take_photo')}
  </button>
{/await}

<button class="btn" on:click={() => dispatch('cancel')}>
  <i class="bi bi-arrow-left" />
  {i18n.t('actions.cancel')}
</button>

<style>
  video {
    width: 100%;
    height: auto;
    display: block;
  }

  #confirm_camera_usage {
    user-select: none;
  }

  .overlay-container {
    position: relative;
  }
  :global(.overlay-container > :first-child + *) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: var(--image);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  :global(#box-outline) {
    stroke: red;
  }
  :global(#outside) {
    fill: none;
  }
</style>
